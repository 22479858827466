<template>
  <div id="Contact">
    <div class="row justify-center">
      <div class="col-xs-0 col-xl-2 bg-white"></div>
      <div class="col-12 col-xl-8 justify-center q-px-md q-ma-auto">
        <div class="row">
          <h2>Contact information</h2>
          <p>For general discussion, we ask that you use the AMPsphere <a href="https://groups.google.com/g/ampsphere-users">mailing list</a> where users can suggest improvements, issues, ongoing projects, etc: <a href="https://groups.google.com/g/ampsphere-users">https://groups.google.com/g/ampsphere-users</a>.</p>
          <p>This is a project hosted by the <a href="https://big-data-biology.org/">Big Data Biology Research Group</a> and you can email <a href="mailto:luispedro@big-data-biology.org">Luis Pedro Coelho</a> with comments/questions if they are not relevant for the wider mailing-list.</p>
          <p>Consult the <a href="https://doi.org/10.1101/2023.08.31.555663">AMPsphere preprint</a> for a full list of authors.</p>
        </div>
      </div>
      <div class="col-xs-0 col-xl-2 bg-white"></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>

</style>
